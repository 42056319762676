import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css'
import './App.css';

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

import Main from "./components/pages/main";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { BsPersonCircle } from "react-icons/bs";
import Entradas from "./components/pages/entradas/new";
import EntradasList from "./components/pages/entradas/list";
import Authentication from './services/authentication-service';
import CierresList from "./components/pages/cierre-caja/list";
import CierreNew from "./components/pages/cierre-caja/new";
import PreciosList from './components/pages/precios/list';
import PrivateRoute from "./components/shared/private-route"
import { UserContext } from './contexts/userContext'
import PreciosNew from './components/pages/precios/new';

import Estadisticas from './components/pages/estadisticas';
import { ADMIN_ROLE, USER_ROLE } from './config/constants';
import ExentoList from './components/pages/exento/list';
import ExentoNew from './components/pages/exento/new';

function App(props) {
  const { hasRole, user, setUser } = React.useContext(UserContext)
  const navigate = useNavigate()

  const logout = () => {
    setUser(null)
    Authentication.logout()
    navigate('/')
  }


  return (<div>
    <div className="App">
      <header>
        <Navbar bg="dark" variant="dark">
          <Container>
            <Navbar.Brand>
              <Link to={"/"} className="nav-link">
                Polideportivo Segui
              </Link>
            </Navbar.Brand>
            {user && <Nav className="justify-content-end">
              {hasRole([USER_ROLE, ADMIN_ROLE]) && <Nav>
                <Link to={"/entradas/new"} className="nav-link">
                  Vender entradas
                </Link>
              </Nav>}
              {hasRole([ADMIN_ROLE, USER_ROLE]) && <Nav>
                <Link to={"/entradas"} className="nav-link">
                  Listar entradas
                </Link>
              </Nav>}
              {hasRole([ADMIN_ROLE, USER_ROLE]) && <Nav>
                <Link to={"/cierres"} className="nav-link">
                  Cierre de caja
                </Link>
              </Nav>}
              {hasRole([ADMIN_ROLE, USER_ROLE]) && <NavDropdown title="Administración">
                <NavDropdown.Item as={Link} to="/precios">
                  Precios
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/exentos">
                  Exentos
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/estadisticas">
                  Estadísticas
                </NavDropdown.Item>
              </NavDropdown>}
              <NavDropdown title={<div><BsPersonCircle />  {user.username} </div>}
                id="nav-dropdown"
                style={{ 'marginLeft': '40px' }}
              >
                <NavDropdown.Item onClick={logout} eventKey="4.1"> Salir </NavDropdown.Item>
              </NavDropdown>
            </Nav>}
          </Container>
        </Navbar>
      </header>

      <div>
        <Routes>
          <Route path='/' element={<Main />} />
          <Route
            path="/entradas/new"
            element={<PrivateRoute roles={[USER_ROLE, ADMIN_ROLE]}>
              <Entradas />
            </PrivateRoute>}
          />
          <Route
            path="/entradas"
            element={<PrivateRoute roles={[USER_ROLE, ADMIN_ROLE]}>
              <EntradasList />
            </PrivateRoute>}
          />
          <Route
            path="/cierres/new"
            element={<PrivateRoute roles={[USER_ROLE, ADMIN_ROLE]}>
              <CierreNew />
            </PrivateRoute>}
          />
          <Route
            path="/cierres"
            element={<PrivateRoute roles={[USER_ROLE, ADMIN_ROLE]}>
              <CierresList />
            </PrivateRoute>}
            roles={[USER_ROLE, ADMIN_ROLE]}
          />
          <Route
            path="/precios/new"
            element={<PrivateRoute roles={[ADMIN_ROLE]}>
              <PreciosNew />
            </PrivateRoute>}
          />
          <Route
            path="/precios/:id"
            element={<PrivateRoute roles={[ADMIN_ROLE]}>
              <PreciosNew />
            </PrivateRoute>}
          />
          <Route path="/precios"
            element={<PrivateRoute roles={[ADMIN_ROLE]}>
              <PreciosList />
            </PrivateRoute>}
          />
          <Route
            path="/exentos/new"
            element={<PrivateRoute roles={[ADMIN_ROLE]}>
              <ExentoNew />
            </PrivateRoute>}
          />
          <Route
            path="/exentos/:id"
            element={<PrivateRoute roles={[ADMIN_ROLE]}>
              <ExentoNew />
            </PrivateRoute>}
          />
          <Route path="/exentos"
            element={<PrivateRoute roles={[ADMIN_ROLE]}>
              <ExentoList />
            </PrivateRoute>}
          />
          <Route path="/estadisticas"
            element={<PrivateRoute roles={[ADMIN_ROLE]}>
              <Estadisticas />
            </PrivateRoute>}
          />
        </Routes>
      </div>
    </div>
  </div>
  );
}

export default App
