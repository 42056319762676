import React from 'react'
import '../../../../App.css'

import CierreCajaService from '../../../../services/cierre-caja-service';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import NumericInput from 'react-numeric-input';
import Modal from 'react-bootstrap/Modal'
import { Link, useNavigate } from "react-router-dom";
import { UIContext } from '../../../../contexts/uiContext'
import EntradasService from '../../../../services/entradas-service';
import { Table } from 'react-bootstrap';

const emptyForm = {}

const billetes = [
  { valor: 10000, name: 'billetes10000' },
  { valor: 2000, name: 'billetes2000' },
  { valor: 1000, name: 'billetes1000' },
  { valor: 500, name: 'billetes500' },
  { valor: 200, name: 'billetes200' },
  { valor: 100, name: 'billetes100' },
  { valor: 50, name: 'billetes50' },
  { valor: 20, name: 'billetes20' },
  { valor: 10, name: 'billetes10' },
]

const monedas = [
  { valor: 10, name: 'monedas10p' },
  { valor: 5, name: 'monedas5p' },
  { valor: 2, name: 'monedas2p' },
  { valor: 1, name: 'monedas1p' },
  { valor: 0.5, name: 'monedas50c' },
  { valor: 0.25, name: 'monedas25c' },
]

function CierreNew() {

  const [amount, setAmount] = React.useState(0)
  const [form, setForm] = React.useState(emptyForm)
  const [showModal, setShowModal] = React.useState(false)
  const { printDataUrl, showMessage, setLoading } = React.useContext(UIContext)
  const [totalCierreCaja, setTotalCierreCaja] = React.useState({})
  const navigate = useNavigate()
  const [validated, setValidated] = React.useState(false);


  const formRef = React.useRef(null);

  React.useEffect(() => {
    setLoading(true)
    EntradasService.getTotalCierreCaja(new Date(), new Date())
      .then(totalCierreCaja => { setTotalCierreCaja(totalCierreCaja) })
      .finally(() => setLoading(false))
  }, [])

  const handleFormValue = (name, value) => {
    const newForm = {
      ...form,
      [name]: value
    }
    const newAmount = calculateAmount(newForm)
    setForm(newForm)
    setAmount(newAmount)
  }

  const handleSuccess = data => {
    setShowModal(false);
    imprimirCierreCaja(data);
    navigate('/cierres');
  }

  function confirmar() {
    const form = formRef.current
    if (form.checkValidity()) {
      setShowModal(true)
    }
    setValidated(true)
  }

  const getCantidadEntradasActivas = () => {
    let cantidad = 0
    totalCierreCaja.mediosPago.forEach(element => {
      cantidad = cantidad + element.cantidadActivos
    });

    return cantidad;
  }

  const getTotalEntradasActivas = () => {
    let total = 0
    totalCierreCaja.mediosPago.forEach(element => {
      total = total + element.totalActivos
    })
    return total;
  }


  const getTotalDiferencia = () => {
    if (totalCierreCaja.mediosPago) {
      const medioPagoEfectivo = totalCierreCaja.mediosPago.find((medioPago) => medioPago.nombre === "EFECTIVO");
      const totalEfectivo = medioPagoEfectivo ? medioPagoEfectivo.totalActivos : 0;
      const montoInicialRestar = form.montoInicial ? form.montoInicial : 0;
      return amount - montoInicialRestar - totalEfectivo;
    }
  }

  const calculateAmount = (form) => {
    const amount = [...billetes, ...monedas].reduce((actual, nuevo) => {
      return actual + (form[nuevo.name] ? form[nuevo.name] * nuevo.valor : 0)
    }, 0);

    return amount
  }

  const submit = () => {
    const body = Object.assign({}, form)
    body.montoTotal = amount
    setLoading(true)
    CierreCajaService.createCierreCaja(body).then(data => {
      handleSuccess(data);
    }).catch(error => {
      console.error(error);
      showMessage('Error guardando el cierre de caja', 'error');
    }).finally(() => setLoading(false))
  }

  const imprimirCierreCaja = (cierreCaja) => {
    CierreCajaService.getReporteCierre(cierreCaja.id).then(dataUrl => {
      printDataUrl(dataUrl);
    }).catch(error => {
      showMessage(error, 'error');
    })
  }

  return (
    !totalCierreCaja.cantidadEntradas ?
      <div>
        <Container className="central-div">
          <Card>
            <h1>
              No existen entradas pendientes de cerrar hasta el momento
            </h1>
          </Card>
        </Container>
      </div>
      :
      <div>
        <Container className="central-div">
          <Card>
            <Card.Body>
              <Col>
                <h1>
                  Nuevo cierre de caja
                </h1>
              </Col>
              <Form noValidate validated={validated}
                className="central-div"
                autoComplete="off"
                style={{ minHeight: '615px' }}
                ref={formRef}
              >
                <Row>
                  <Col md={4}>
                    <h1>Billetes</h1>
                    {billetes.map((billete, index) => {
                      return <Form.Group controlId={billete.name} key={index}>
                        <Row >
                          <Col md={3}>
                            <Form.Label style={{ marginTop: '8px' }}>$ {billete.valor}</Form.Label>
                          </Col>
                          <Col>
                            <NumericInput mobile className="form-control" value={form[billete.name]} min={0}
                              onChange={value => handleFormValue(billete.name, value)}
                            />
                          </Col>
                          <Col md={3} style={{ paddingTop: '8px' }}>
                            {form[billete.name] && <span> = $ {form[billete.name] * billete.valor} </span>}
                          </Col>
                        </Row>
                      </Form.Group>
                    })}
                  </Col>
                  <Col md={4}>
                    <h1>Monedas</h1>
                    {monedas.map((moneda, index) => {
                      return <Form.Group controlId={moneda.name} key={index}>
                        <Row >
                          <Col md={3}>
                            <Form.Label style={{ marginTop: '8px' }}>$ {moneda.valor}</Form.Label>
                          </Col>
                          <Col>
                            <NumericInput mobile className="form-control" value={form[moneda.name]} min={0}
                              onChange={value => handleFormValue(moneda.name, value)}
                            />
                          </Col>
                          <Col md={3}>
                            {form[moneda.name] && <span> = $ {form[moneda.name] * moneda.valor} </span>}
                          </Col>
                        </Row>
                      </Form.Group>
                    })}
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label><h1>Saldo inicial</h1></Form.Label>
                      <Form.Control type="text"
                        name="montoInicial"
                        value={form.montoInicial}
                        onChange={e => handleFormValue('montoInicial', e.target.value)}
                        required
                      />
                    </Form.Group>

                    {totalCierreCaja.mediosPago && <div> <Table striped bordered hover size="sm">
                      <thead>
                        <tr>
                          <th>Medio</th>
                          <th>Montos</th>
                          <th>Cantidad entradas</th>
                        </tr>
                      </thead>
                      <tbody>
                        {totalCierreCaja.mediosPago.map((medioPago, index) =>
                          <tr key={index}>
                            <td>{medioPago.nombre}</td>
                            <td>$ {medioPago.totalActivos.toFixed(2)}</td>
                            <td>{medioPago.cantidadActivos}</td>
                          </tr>
                        )}
                        <tr striped="true" bordered="true" hover="true" size="sm">
                          <td><b>TOTAL</b></td>
                          <td><b> $ {getTotalEntradasActivas().toFixed(2)}</b></td>
                          <td><b>{getCantidadEntradasActivas()}</b></td>
                        </tr>
                      </tbody>
                    </Table>
                      <div className="resumen-caja-div">
                        Total arqueo efectivo: <br /> ${amount}
                      </div>
                      <br />

                      {getTotalDiferencia() > 0 && <div className="resumen-caja-div error">
                        Sobran: <br />
                        $ {getTotalDiferencia().toFixed(2)}
                      </div>}

                      {0 > getTotalDiferencia() && <div className="resumen-caja-div error">
                        Faltan: <br />
                        $ {Math.abs(getTotalDiferencia().toFixed(2))}
                      </div>}

                      {getTotalDiferencia() === 0 && <div className="resumen-caja-div success">
                        Cierre de caja correcto
                      </div>}

                    </div>
                    }
                    <br />
                  </Col>
                </Row>
              </Form>
              <Row className="row-mb-5">
                <Col>
                  <div className="central-div">
                    <Link to={"/cierres"}>
                      <Button size="lg" variant="danger" className="button-mr-5">
                        Cancelar
                      </Button>
                    </Link>
                    <Button size="lg" type="submit"
                      onClick={() => confirmar()} disabled={getTotalDiferencia() !== 0} >
                      Cerrar caja
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>

        <Modal show={showModal} onHide={() => setShowModal(false)} animation={false} centered={true}>
          <Modal.Header closeButton>
            <Modal.Title><b>Confirmación</b></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>¿Confirmar el cierre de caja?</h3>
            <br />
            <h3>Atención: Cambiar papel de impresora.</h3>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={() => submit()}>
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>
      </div >
  )
}

export default CierreNew