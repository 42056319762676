import React from 'react';

import { TailSpin } from 'react-loader-spinner'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export const UIContext = React.createContext(null);

export const UIProvider = (props) => {

  const [loading, setLoading] = React.useState(false)

  const iframeRef = React.useRef(null);

  const printDataUrl = dataUrl => {
    iframeRef.current.src = dataUrl;
  }

  const showMessage = (message, type) => {
    if (type === 'success') {
      toast.success(message)
    } else if (type === 'error') {
      toast.error(message)
    } else {
      toast(message)
    }
  }
  return (
    <UIContext.Provider value={{ showMessage, printDataUrl, setLoading }}>
      {props.children}
      {loading && (<div className="overlay">
        <div className="spinner-container">
          <TailSpin
            visible={loading}
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
            wrapperClass=""
          />
        </div>
      </div>)}
      <ToastContainer newestOnTop />
      <iframe name="entradaReporteFrame"
        style={{ width: '1px', "height": "1px", "position": "absolute", "left": "-9999px" }}
        title="title"
        ref={iframeRef}
        onLoad={() => {
          if (iframeRef.current.src) {
            window.frames['entradaReporteFrame'].print();
          }
        }}
      >
      </iframe>
    </UIContext.Provider>
  )
}