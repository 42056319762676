import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import setupAxiosInterceptors from './config/axios-interceptor';

import { UserProvider } from './contexts/userContext'
import { UIProvider } from './contexts/uiContext'

setupAxiosInterceptors(() => document.dispatchEvent(new Event('invalidToken')));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <UserProvider>
      <UIProvider>
        <App />
      </UIProvider>
    </UserProvider>
  </BrowserRouter>
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
