import React from 'react';
import { Route } from "react-router-dom";

import { UserContext } from '../../contexts/userContext'


function PrivateRoute(props) {

  const { hasRole } = React.useContext(UserContext)

  return hasRole(props.roles) ?
    <React.Fragment >
      {props.children}
    </React.Fragment>
    :
    <h1>Forbidden</h1>
}

export default PrivateRoute;
